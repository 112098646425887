import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close'
import { DeleteOutlineOutlined, FilePresent } from '@mui/icons-material'
import { postAsync, formPostAsync, getAsync, deleteAsync } from '../common/HttpClientV2'

export const AttachmentModal = ({ visible, data = {}, handleClose = () => { } }) => {
    const [files, setFiles] = useState([]);
    const [tranId, setTranId] = useState(0);
    const [newFile, setNewFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [deleteVisible, setDeleteVisible] = useState(false);
    const newJwt = localStorage.getItem('newApiToken');
    const [deleteFileInfo, setDeleteFileInfo] = useState({ id: '', name: '' });

    useEffect(() => {
        setFiles([]);
        if (data.transactionId > 0) {
            setTranId(data.transactionId);
            loadFile(data.transactionId);
        }
    }, [data]);

    const loadFile = async (transactionId) => {
        var res = await postAsync('v1/treasury/files', { transactionId: transactionId }, {
            Authorization: `Bearer ${newJwt}`
        })
        setFiles(res.result);
    }

    const postFile = async () => {
        var formData = new FormData();
        formData.append('NewFile', newFile);
        formData.append('TransactionId', tranId);
        //var antiforgery = await getAsync('v1/antiforgery/token', { Authorization: `Bearer ${newJwt}` });
        //await formPostAsync('v1/treasury/files/upload', formData, { 'X-XSRF-TOKEN': antiforgery.result }, newJwt);
        await formPostAsync('v1/treasury/files/upload', formData, null, newJwt);
        document.getElementById('btnReset').click();
        loadFile(tranId);
    }

    const deleteFile = async (id, name) => {
        setDeleteFileInfo({ id: id, name: name });
        setDeleteVisible(true);
    }

    const downloadFile = async (id, name) => {
        var fileContents = await getAsync(`v1/file/${id}`, { Authorization: `Bearer ${newJwt}` }, 'blob');
        const blob = new Blob([fileContents]);
        const reader = new FileReader();

        reader.onload = (event) => {
            var lnk = document.createElement('a');
            lnk.href = event.target.result;
            lnk.download = name;
            lnk.click();
        };

        reader.readAsDataURL(blob);
    }

    const handleDeleteClose = () => {
        setDeleteVisible(false);
    }

    const confirmDeleteFile = async () => {
        await deleteAsync(`v1/file/${deleteFileInfo.id}`, null, { Authorization: `Bearer ${newJwt}` });
        setDeleteVisible(false);
        loadFile(tranId);
    }

    return (
        <>
            <Modal
                onHide={handleClose}
                show={visible}
                style={{ width: '40%', marginLeft: '30%' }} >
                <div style={{ border: '1px solid white' }}>
                    <Modal.Header
                        style={{ backgroundColor: '#222429', border: 'none' }}
                    >
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                color: 'white'
                            }}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#222429' }}>
                        <div>
                            <form>

                                <div style={{ textAlign: 'left' }}>
                                    <label style={{ marginLeft: '10px' }}>Pick a file to upload:</label>
                                    <input type="text" id="txtSelectedFile" value={fileName} readOnly={true} style={{
                                        width: '95%', marginRight: '10px', marginTop: '0px', height: '25px', fontSize: '12px', textAlign: 'left',
                                        paddingLeft: '20px', color: '#000'
                                    }} />
                                    <FilePresent style={{ marginTop: '-5px', cursor: 'hand' }} onClick={() => {
                                        document.getElementById('fileUpload').click();
                                    }} />
                                    <input type="file" id="fileUpload" style={{ display: 'none' }} onChange={(e) => {
                                        if (e.currentTarget.files.length > 0) {
                                            setFileName(e.currentTarget.files[0].name);
                                            setNewFile(e.currentTarget.files[0]);
                                        }
                                    }} />
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    <button type="button" style={{ marginRight: '10px' }} onClick={() => postFile()}>Upload</button>
                                    <button id="btnReset" type="reset" onClick={() => { setFileName(''); setNewFile(null); }}>Reset</button>
                                </div>
                            </form>

                            <div class="table-responsive" style={{ marginTop: '20px' }}>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50px' }}>No.</th>
                                            <th style={{ width: 'auto' }}>Filename</th>
                                            <th style={{ width: '50px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (files && files.length > 0 ? files.map((row, idx) => (
                                                <tr key={idx}>
                                                    <td style={{ width: '50px', textAlign: 'center' }}>{idx + 1}</td>
                                                    <td style={{ width: 'auto', textAlign: 'left' }}><a title={row.filename} href="#" onClick={() => downloadFile(row.id, row.filename)}>{row.filename}</a></td>
                                                    <td style={{ width: '50px', textAlign: 'center' }}>
                                                        <DeleteOutlineOutlined onClick={() => deleteFile(row.id, row.filename)} style={{ width: '19px', height: '19px', color: '#FE0000', cursor: 'hand' }} />
                                                    </td>
                                                </tr>
                                            )) : <td colspan="3" style={{ textAlign: 'center' }}>No attachment</td>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal
                onHide={handleDeleteClose}
                show={deleteVisible}
                style={{ width: '20%', marginLeft: '40%' }} >
                <div style={{ border: '1px solid white' }}>
                    <Modal.Header
                        style={{ backgroundColor: '#ff0000', border: 'none' }}
                    >
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                color: 'white'
                            }}
                            onClick={handleDeleteClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#ff0000' }}>
                        <div>
                            <div>Are you sure you want to remove file "{deleteFileInfo.name}"?</div>
                            <div style={{ marginTop: '10px', textAlign: 'right' }}>
                                <button type="button" onClick={() => confirmDeleteFile()}>Confirm</button>&nbsp;&nbsp;
                                <button type="button" onClick={() => setDeleteVisible(false)}>Cancel</button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    )
}
