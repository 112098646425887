export const getUserId = () => localStorage.getItem('sub_Id')

export function numberWithCommas (x, ignoreDecimal = false, noOfDec = 2) {
  if (isNaN(x) || x == null) { return x }

  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if(!ignoreDecimal) {
    var part2 = `.${parts.length > 1 ? parts[1] : ''}`;
    for(var i = part2.length; i <= noOfDec; i++)
      part2 += '0';
    return parts[0] + part2;
  }
  else {
    return parts.length > 0 ? parts[0] : 0;
  }
}
