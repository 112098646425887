import React, { useState } from "react";
import { REDIRECT_URI, LOCATION_URL } from "../../config/env";
import jwt_decode from "jwt-decode";
import axios from "axios";
import KeyclokaAuthUrl from "../AuthEndpointSetup/Keycloak";
import { useLocation } from "react-router-dom";
import { postAsync } from "../../common/HttpClientV2";
const qs = require("qs");

const Layout = () => {
  const location = useLocation();
  if (location.pathname === "/") {
    localStorage.clear();
    window.location = KeyclokaAuthUrl;
  } else if (
    location.pathname === "/PMS/dashboard" ||
    location.pathname === "/PMS/Admin_dashboard"
  ) {
    window.location.href = KeyclokaAuthUrl;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const pCode = urlParams.get('code');

  const key = {
    grant_type: "authorization_code",
    code: pCode,
    redirect_uri: REDIRECT_URI,
  };
  
  // login-api
  const KeycloakToken = async () => {
    const data1 = qs.stringify(key);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/keycloak_superset_login`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "enable-cors": true,
        "bearer-only": true,
      },
      data: data1,
    };
    await axios(config).then(async function (response) {
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("id_token", response.data.id_token);
      const decoded = jwt_decode(response.data.access_token);
      const subId = decoded.sub;
      localStorage.setItem("sub_Id", decoded.sub);
      localStorage.setItem("name", decoded.preferred_username);

      const userRole =
        decoded.resource_access["nodejs-microservice"] == null
          ? decoded.realm_access.roles
              .toLocaleString()
              .toLowerCase()
              .split(",")
              .filter((rol) => rol.startsWith("app-"))
              .map((s) => s.slice(4))
              .toLocaleString()
              .toLowerCase()
          : decoded.resource_access["nodejs-microservice"].roles
              .toLocaleString()
              .toLowerCase();
      localStorage.setItem("role", userRole);
      const username = decoded.preferred_username;
      const email = decoded.email;

      var newApiToken = await postAsync('v1/user/sign-in', { userId: decoded.sub, username: decoded.preferred_username, roles: userRole.split(',') });
      console.log(newApiToken.result.token)
      localStorage.setItem('newApiToken', newApiToken.result.token);

      keycloakLogin(userRole, subId, username, email);

      if(userRole.includes("superadmin") || userRole.includes("admin") || userRole.includes("accountant") || userRole.includes("crypto")) {
        window.location = `${LOCATION_URL}/PMS/Admin_dashboard`;
      }
      else if(userRole.includes("superadmin") || userRole.includes("admin") || userRole.includes("treasury")) {
        window.location = `${LOCATION_URL}/PMS/TreasuryDashboard`;
      }      
      else if(userRole.includes("superadmin") || userRole.includes("admin") || userRole.includes("realestate")) {
        window.location = `${LOCATION_URL}/PMS/Property`;
      }            
      else if (userRole.includes("user")) {
        window.location = `${LOCATION_URL}/PMS/dashboard`;
      }

    });
  };
  const keycloakLogin = async (userRole, subId, username, email) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/add_keycloak_user`,
      data: {
        user_role: userRole,
        user_id: subId,
        username,
        email,
      },
    };
    await axios(config).then(function (response) {
      console.log(response);
    });
  };
  
  if (
    location.pathname === "/callback" &&
    localStorage.getItem("role") == null
  ) {
    KeycloakToken();
  }
  else {
    let userRole = localStorage.getItem("role").split(",");
    if(userRole.includes("superadmin") || userRole.includes("admin") || userRole.includes("accountant")) {
      window.location = `${LOCATION_URL}/PMS/Admin_dashboard`;
    }
    else if (userRole.includes("user")) {
      window.location = `${LOCATION_URL}/PMS/dashboard`;
    }
  }

  return (null);
};
export default Layout;
