import { Autocomplete, Paper, TextField } from '@mui/material'
import React from 'react'
import { Row } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getUserId } from '../helpers/common';

export default function Filters ({ portfolios, filters, setFilters, bankAccounts, bankAccountType, bo, boType, currency, regions, onFormSubmit = () => {} }) {
  const useStyles = makeStyles({
    noOptions: {
      color: 'white'
    }
  })

  const styles = useStyles()
  return (
    <React.Fragment>
    <Row style={{ marginLeft: '1px', marginBottom: '20px', gap: '10px' }}>
        <Autocomplete
            disablePortal
            className='p-2'
            classes={{
              noOptions: styles.noOptions
            }}
            id="controllable-states-demo"
            value={portfolios.find((p) => { return p.id == filters.portfolioId })?.name || null}
            options={portfolios?.map((e) => e.name)}
            onChange={(e, k) => setFilters({ ...filters, portfolioId: k ? portfolios.find((p) => { return p.name == k }).id : '' })}
            PaperComponent={({ children }) => (
            <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                {children}
            </Paper>
            )}
            style={{
              fill: 'white',
              boxShadow: 'none',
              fontSize: '10px',
              borderRadius: '30%'
            }}
            sx={{
              width: '200px',
              height: '32px',
              '.MuiButtonBase-root': {
                color: 'white'
              },
              '.MuiOutlinedInput-root': {
                borderRadius: '4px',
                width: '190px',
                height: '32px',
                fontSize: '14px',
                border: '1px solid #d9d9d9 !important',
                left: '-9px'
              },
              '.MuiInputBase-input': {
                height: '1rem'
              },
              '.MuiInputLabel-root': {
                top: '-0.5em',
                marginLeft: '-0.5em'
              }
            }}
            renderInput={(params) => (
            <TextField
                {...params}
                style={{ color: 'white', fontSize: '14px' }}
                label="Portfolios"
            />
            )}
        />

        {
          filters.portfolioId && (
            <>
             <Autocomplete
                disablePortal
                className='p-2'
                classes={{
                  noOptions: styles.noOptions
                }}
                id="controllable-states-demo"
                value={filters.currency || null}
                options={currency?.map((c) => c.Currency)}
                onChange={(e, k) => setFilters({ ...filters, currency: k })}
                PaperComponent={({ children }) => (
                <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                    {children}
                </Paper>
                )}
                style={{
                  fill: 'white',
                  boxShadow: 'none',
                  fontSize: '10px',
                  borderRadius: '30%'
                }}
                sx={{
                  width: '200px',
                  height: '32px',
                  '.MuiButtonBase-root': {
                    color: 'white'
                  },
                  '.MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    width: '190px',
                    height: '32px',
                    fontSize: '14px',
                    border: '1px solid #d9d9d9 !important',
                    left: '-9px'
                  },
                  '.MuiInputBase-input': {
                    height: '1rem'
                  },
                  '.MuiInputLabel-root': {
                    top: '-0.5em',
                    marginLeft: '-0.5em'
                  }
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ color: 'white', fontSize: '14px' }}
                    label="Currency"
                />
                )}
            />

            <Autocomplete
                disablePortal
                className='p-2'
                classes={{
                  noOptions: styles.noOptions
                }}
                id="controllable-states-demo"
                value={filters.boCode || null}
                options={bo?.map((e) => e.Code)}
                onChange={(e, k) => setFilters({ ...filters, boCode: k })}
                PaperComponent={({ children }) => (
                <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                    {children}
                </Paper>
                )}
                style={{
                  fill: 'white',
                  boxShadow: 'none',
                  fontSize: '10px',
                  borderRadius: '30%'
                }}
                sx={{
                  width: '200px',
                  height: '32px',
                  '.MuiButtonBase-root': {
                    color: 'white'
                  },
                  '.MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    width: '190px',
                    height: '32px',
                    fontSize: '14px',
                    border: '1px solid #d9d9d9 !important',
                    left: '-9px'
                  },
                  '.MuiInputBase-input': {
                    height: '1rem'
                  },
                  '.MuiInputLabel-root': {
                    top: '-0.5em',
                    marginLeft: '-0.5em'
                  }
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ color: 'white', fontSize: '14px' }}
                    label="BO"
                />
                )}
            />

            <Autocomplete
                disablePortal
                className='p-2'
                classes={{
                  noOptions: styles.noOptions
                }}
                id="controllable-states-demo"
                value={filters.bank || null}
                options={[... new Set(bankAccounts?.map((e) => e.BankName))]}
                onChange={(e, k) => setFilters({ ...filters, bank: k })}
                PaperComponent={({ children }) => (
                <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                    {children}
                </Paper>
                )}
                style={{
                  fill: 'white',
                  boxShadow: 'none',
                  fontSize: '10px',
                  borderRadius: '30%'
                }}
                sx={{
                  width: '200px',
                  height: '32px',
                  '.MuiButtonBase-root': {
                    color: 'white'
                  },
                  '.MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    width: '190px',
                    height: '32px',
                    fontSize: '14px',
                    border: '1px solid #d9d9d9 !important',
                    left: '-9px'
                  },
                  '.MuiInputBase-input': {
                    height: '1rem'
                  },
                  '.MuiInputLabel-root': {
                    top: '-0.5em',
                    marginLeft: '-0.5em'
                  }
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ color: 'white', fontSize: '14px' }}
                    label="Bank"
                />
                )}
            />

            <Autocomplete
                disablePortal
                className='p-2'
                classes={{
                  noOptions: styles.noOptions
                }}
                id="controllable-states-demo"
                value={filters.acctType || null}
                options={bankAccountType?.map((e) => e.Type)}
                onChange={(e, k) => setFilters({ ...filters, acctType: k })}
                PaperComponent={({ children }) => (
                <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                    {children}
                </Paper>
                )}
                style={{
                  fill: 'white',
                  boxShadow: 'none',
                  fontSize: '10px',
                  borderRadius: '30%'
                }}
                sx={{
                  width: '200px',
                  height: '32px',
                  '.MuiButtonBase-root': {
                    color: 'white'
                  },
                  '.MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    width: '190px',
                    height: '32px',
                    fontSize: '14px',
                    border: '1px solid #d9d9d9 !important',
                    left: '-9px'
                  },
                  '.MuiInputBase-input': {
                    height: '1rem'
                  },
                  '.MuiInputLabel-root': {
                    top: '-0.5em',
                    marginLeft: '-0.5em'
                  }
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ color: 'white', fontSize: '14px' }}
                    label="Bank Type"
                />
                )}
            />

            <Autocomplete
                disablePortal
                className='p-2'
                classes={{
                  noOptions: styles.noOptions
                }}
                id="controllable-states-demo"
                value={filters.region || null}
                options={regions?.map((e) => e.Region)}
                onChange={(e, k) => setFilters({ ...filters, region: k })}
                PaperComponent={({ children }) => (
                <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                    {children}
                </Paper>
                )}
                style={{
                  fill: 'white',
                  boxShadow: 'none',
                  fontSize: '10px',
                  borderRadius: '30%'
                }}
                sx={{
                  width: '200px',
                  height: '32px',
                  '.MuiButtonBase-root': {
                    color: 'white'
                  },
                  '.MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    width: '190px',
                    height: '32px',
                    fontSize: '14px',
                    border: '1px solid #d9d9d9 !important',
                    left: '-9px'
                  },
                  '.MuiInputBase-input': {
                    height: '1rem'
                  },
                  '.MuiInputLabel-root': {
                    top: '-0.5em',
                    marginLeft: '-0.5em'
                  }
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ color: 'white', fontSize: '14px' }}
                    label="Region"
                />
                )}
            />
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className='p-2'
                  label={'Month'}
                  views={['month', 'year']}
                  renderInput={(props) => <TextField  {...props} size='small' helperText={null} InputLabelProps={{ className: 'p-2' }} />}
                  onChange={(newValue) => { 
                      filters.monthYear = newValue.$d;
                      setFilters({... filters})  }}
                  InputProps={{
                    sx: {
                      '&.MuiOutlinedInput-root': {
                        borderRadius: '4px',
                        width: '190px',
                        height: '32px',
                        fontSize: '14px',
                        border: '1px solid #d9d9d9 !important',
                        left: '-9px'
                      },
                      '& .MuiInputLabel-outlined': {
                        border: '1px solid #FF0000 !important'
                      }
                    }
                  }}
                  value={filters.monthYear}
                  openTo='month'
                />
            </LocalizationProvider>
            </>
          )
        }

    <div className='p-1'>
      <button
        className='apply'
        onClick={onFormSubmit}
        style={{ marginLeft: '0' }}
        >
        Apply
      </button>
      <button
        className='clear'
        onClick={() => { setFilters({ userId: getUserId() }) }} >
        Clear
      </button>
    </div>
  </Row>
   </React.Fragment>
  )
}
